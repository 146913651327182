import React, { FC, useState } from 'react';
import banner1 from 'images/home/banner/Banner-1.png';
import banner2 from 'images/home/banner/Banner-2.png';
import banner3 from 'images/home/banner/Banner-3.png';
import banner4 from 'images/home/banner/Banner-4.png';
import banner5 from 'images/home/banner/Banner-5.png';
import banner6 from 'images/home/banner/Banner-6.png';

import backgroundLineSvg from 'images/Moon.svg';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Next from 'shared/NextPrev/Next';
import Prev from 'shared/NextPrev/Prev';
import useInterval from 'react-use/lib/useInterval';
import useBoolean from 'react-use/lib/useBoolean';

interface Hero2DataType {
    image: string;
    heading: string;
    subHeading: string;
    btnText: string;
    btnLink: string;
}
export interface SectionHero2Props {
    className?: string;
}

const DATA: Hero2DataType[] = [
    {
        image: banner1,
        heading: 'Unlock Connections, Tap to Transform: Your Network in a Touch',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    },

    {
        image: banner2,
        heading: 'Connect with a Tap: Your Digital Identity in the Palm of Their Hand.',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    },
    {
        image: banner3,
        heading: 'Your Digital Identity, Anytime, anywhere. Just Tap & Share',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    },
    {
        image: banner4,
        heading: 'Experience the Future: NFC Smart Cards by Instaacard',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    },
    {
        image: banner5,
        heading: 'Seamless Connectivity, Infinite Possibilities',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    },
    {
        image: banner6,
        heading: 'Instaacard: Your Gateway to Smart Connectivity',
        subHeading: 'Instaacard: Just Tap & Share 🔥',
        btnText: 'Explore now',
        btnLink: '/'
    }
];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = '' }) => {
    // =================
    const [indexActive, setIndexActive] = useState(0);
    const [isRunning, toggleIsRunning] = useBoolean(true);

    useInterval(
        () => {
            handleAutoNext();
        },
        isRunning ? 5500 : null
    );
    //

    const handleAutoNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
        handleAfterClick();
    };

    const handleClickPrev = () => {
        setIndexActive((state) => {
            if (state === 0) {
                return DATA.length - 1;
            }
            return state - 1;
        });
        handleAfterClick();
    };

    const handleAfterClick = () => {
        toggleIsRunning(false);
        if (TIME_OUT) {
            clearTimeout(TIME_OUT);
        }
        TIME_OUT = setTimeout(() => {
            toggleIsRunning(true);
        }, 1000);
    };
    // =================

    const renderItem = (index: number) => {
        const isActive = indexActive === index;
        const item = DATA[index];
        if (!isActive) {
            return null;
        }
        return (
            <div
                className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
                key={index}
            >
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
                    {DATA.map((_, index) => {
                        const isActive = indexActive === index;
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    setIndexActive(index);
                                    handleAfterClick();
                                }}
                                className={`relative px-1 py-1.5 cursor-pointer`}
                            >
                                <div className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}>
                                    {isActive && (
                                        <div
                                            className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                                                isActive ? ' ' : ' '
                                            }`}
                                        ></div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                <Prev
                    className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
                    btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                    svgSize="w-6 h-6"
                    onClickPrev={handleClickPrev}
                />
                <Next
                    className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
                    btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                    svgSize="w-6 h-6"
                    onClickNext={handleClickNext}
                />

                {/* BG */}
                <div className="absolute inset-0 bg-[#fefce8]">
                    {/* <div className="absolute inset-0 bg-[#F7F0EA]"> */}
                    <img className="absolute w-full h-full object-contain" src={backgroundLineSvg} alt="hero" />
                </div>

                <div className="relative container pb-0 pt-14 sm:pt-20 lg:py-44">
                    <div className={`relative z-[1] w-full max-w-3xl space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}>
                        <div className="space-y-5 sm:space-y-6">
                            <span className="nc-SectionHero2Item__subheading block text-base md:text-xl text-slate-700 font-medium">
                                {item.subHeading}
                            </span>
                            <h2 className="nc-SectionHero2Item__heading font-semibold text-3xl sm:text-4xl md:text-4xl xl:text-5xl 2xl:text-5xl !leading-[114%] text-slate-900">
                                {item.heading}
                            </h2>
                        </div>

                        <ButtonPrimary
                            className="nc-SectionHero2Item__button dark:bg-slate-900"
                            sizeClass="py-3 px-6 sm:py-5 sm:px-9"
                            href={item.btnLink as any}
                        >
                            <span>{item.btnText}</span>
                            <span>
                                <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22 22L20 20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </ButtonPrimary>
                    </div>
                    <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
                        <img
                            className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
                            src={item.image}
                            alt={item.heading}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
