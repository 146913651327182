import React, { FC, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// import Input from "shared/Input/Input";
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormFeedback } from 'reactstrap';
import Input from 'shared/Input/Input';
import { AppDispatch, RootState } from 'reduxStore/store';
import { bulkCreate, clearCartList, getCart } from '../../reduxStore/store/cart/index';
import { customerLogin } from '../../reduxStore/store/user';
import { toast } from 'react-toastify';

export interface PageLoginProps {
    className?: string;
}
type FormValues = {
    email: string;
    password: string;
};

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
    // const dispatch = useDispatch();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);

    const loginCartData = useSelector((state: RootState) => state?.CartSlice?.cart);
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setButtonStatus(true);
        const customerData = {
            email: data?.email,
            password: data?.password
        };

        const CartData = loginCartData?.map((item) => {
            return {
                productId: item?.ProductMaster?.id,
                quantity: item?.quantity,
                name: item?.itemData?.name,
                company: item?.itemData?.company,
                designation: item?.itemData?.designation,
                // email: item?.itemData?.email,
                mobile: item?.itemData?.mobile,
                companyLogo: item?.itemData?.companyLogo,
                designFile: item?.itemData?.designFile,
                variant: item?.itemData?.variant
            };
        });

        dispatch(customerLogin(customerData))
            .unwrap()
            .then((res) => {
                if (CartData && CartData.length > 0) {
                    dispatch(
                        bulkCreate({
                            cartData: CartData
                        })
                    )
                        .unwrap()
                        .then((res) => {
                            dispatch(clearCartList());
                            dispatch(getCart());
                        })
                        .catch(() => {
                            //
                        });
                } else {
                    dispatch(clearCartList());
                    dispatch(getCart());
                }
                setButtonStatus(false);
                return navigate('/');
            })
            .catch((err) => {
                setButtonStatus(false);
                return toast.error(err?.response?.data?.message || 'Something went wrong!');
            });
    };
    const validationScheme = yup.object().shape({
        email: yup.string().email('Must be a valid email.').required('Email is required'),
        password: yup.string().required('Password is required.')
    });
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Login || Instaacard</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Login
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
                    {/* OR */}
                    {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                    {/* FORM */}
                    <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
                            <Input
                                type="email"
                                placeholder="example@example.com"
                                className="mt-1"
                                style={{ borderColor: errors.email ? '#ea5455' : '' }}
                                // onInvalid={errors?.email && true}
                                // onInvalid={(e) => {
                                //   errors.password && true;
                                // }}
                                {...register('email')}
                            />
                            {errors && errors.email && <FormFeedback style={{ color: '#ea5455' }}>{errors.email.message}</FormFeedback>}
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                Password
                                <Link to="/forgot-pass" className="text-sm text-green-600">
                                    Forgot password?
                                </Link>
                            </span>
                            <Input
                                type="password"
                                // value={"Admin@123"}
                                style={{ borderColor: errors.password ? '#ea5455' : '' }}
                                className="mt-1"
                                {...register('password')}
                            />
                            {errors && errors.password && (
                                <FormFeedback style={{ color: '#ea5455' }}>{errors.password.message}</FormFeedback>
                            )}
                        </label>
                        <ButtonPrimary type="submit" disabled={buttonStatus}>
                            Continue
                        </ButtonPrimary>
                    </Form>
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        New user? {` `}
                        <Link className="text-green-600" to="/signup">
                            Create an account
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PageLogin;
