import React, { FC, Fragment, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import LikeButton from 'components/LikeButton';
import AccordionInfo from './AccordionInfo';
import BagIcon from 'components/BagIcon';
import NcInputNumber from 'components/NcInputNumber';
// import { PRODUCTS } from 'data/data';
import { NoSymbolIcon, ClockIcon, SparklesIcon } from '@heroicons/react/24/outline';
import IconDiscount from 'components/IconDiscount';
import Prices from 'components/Prices';
import { toast } from 'react-toastify';
// import detail1JPG from 'images/products/detail1.jpg';
// import detail2JPG from 'images/products/detail2.jpg';
// import detail3JPG from 'images/products/detail3.jpg';
import Policy from './Policy';
import SectionPromo2 from 'components/SectionPromo2';
import ModalViewAllReviews from './ModalViewAllReviews';
// import NotifyAddToCart from 'components/NotifyCartNew';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/store';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getProductById } from '../../reduxStore/store/products/index';
import { Product } from 'reduxStore/store/products/class';
import { Form, FormFeedback } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'shared/Input/Input';
import { isUserLoggedIn } from 'utils/User';
import { addToCart, uploadImage } from '../../reduxStore/store/cart/index';
import DefaultImage from '../../images/placeholder-small.png';
import service from 'service/constant';
import SectionSliderCollections from 'components/SectionSliderLargeProduct';
// import Select from 'shared/Select/Select';

export interface ProductDetailPageProps {
    className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = '' }) => {
    // const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
    // const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

    const allVariant = ['White PVC Glossy', 'White PVC Matt'];
    const ProductData: Product = useSelector((state: RootState) => state?.productSlice?.productById);

    const [variantActive, setVariantActive] = React.useState<string>(allVariant[0]);
    const [qualitySelected, setQualitySelected] = React.useState(1);
    const [selectImage, setSelectedImage] = useState();
    const [selectedDesignFile, setSelectedDesignFile] = useState();

    const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] = useState(false);

    const url = service.IMAGE_URL;
    const image = ProductData?.ProductImageMappings?.[0]?.ProductImage?.path
        ? `${url}${ProductData?.ProductImageMappings?.[0]?.ProductImage?.path}`
        : DefaultImage;

    type FormValues = {
        name?: string | undefined;
        email?: string | undefined;
        mobile?: number | undefined;
        company?: string | undefined;
        designation?: string | undefined;
        companyLogo?: string | undefined;
        designFile?: string | undefined;
        variant?: string | undefined;
    };
    const validationScheme = yup.object().shape({
        // email: yup.string().when('$ProductData.isCustom', (isCustom, schema) => {
        //     return ProductData.isCustom ? schema : schema.email('Must be a valid email.').required('Email is required');
        // }),
        name: yup.string().when('$ProductData.isCustom', (isCustom, schema) => {
            return ProductData.isCustom ? schema : schema.required('Name is required.');
        }),
        company: yup.string(),
        // designation: yup.string().when('company', {
        //     is: (company: string | undefined, currentSchema: yup.StringSchema) => company && company.length > 0,
        //     then: yup.string().required('Designation is required.'),
        //     otherwise: yup.string().notRequired()
        // }),
        designation: yup
            .string()
            .nullable()
            .when('company', (company, schema) => {
                return company && company.length > 0 ? schema.required('Designation is required.') : schema;
            }),
        companyLogo: yup.string(),
        mobile: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .when('$ProductData.isCustom', (isCustom, schema) => {
                return ProductData.isCustom
                    ? schema
                    : schema
                          .typeError('Mobile must be a number.')
                          .required('Mobile is required.')
                          .test('len', 'Number must be at least 10 digits.', (val) => val.toString().length >= 10);
            }),

        designFile: yup.mixed().when('$ProductData.isCustom', (isCustom, schema) => {
            return !ProductData.isCustom ? schema : schema.required('Design File is required.');
        }),
        variant: yup.mixed().when('$ProductData.isCustom', (isCustom, schema) => {
            return !ProductData.isCustom ? schema : schema.required('Variant is required.');
        })
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<any>({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const ProductMaster = {
        id: ProductData?.id,
        ProductImageMappings: ProductData?.ProductImageMappings,
        name: ProductData?.name,
        price: ProductData?.price,
        isSale: ProductData?.isSale,
        saleStatus: ProductData?.saleStatus,
        salePrice: ProductData?.salePrice,
        isCustom: ProductData?.isCustom
    };

    const allowedType = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
        'application/coreldraw',
        'image/vnd.adobe.photoshop',
        'application/postscript'
    ];

    const handleImageUpload = async (e: any, setFileValue: any) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        } else if (file.size > 15000000) {
            toast.error('File size exceeds maximum limit 15 MB.');
            e.target.value = '';
            return;
        } else if (file && !allowedType.includes(file.type)) {
            toast.error('Only .jpg, .jpeg, .png, .pdf, .cdr, .psd formats are allowed.');
            e.target.value = '';
            return;
        } else {
            const formData = new FormData();
            formData.append('companyLogo', e.target.files?.[0] as Blob);

            console.log(e.target.files);
            return await dispatch(uploadImage(formData))
                .unwrap()
                .then((res) => {
                    setFileValue(res.data.fileURL);
                    console.log('--->', res.data);
                })
                .catch((err) => console.error('err', err));
        }
    };
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            if (ProductData.isCustom && !selectedDesignFile) {
                return toast.error('Design File is required.');
            }
            var productDetails: any = {};

            var itemCartData: any = {};
            if (ProductData.isCustom) {
                itemCartData = {
                    designFile: selectedDesignFile,
                    variant: variantActive
                };
            } else {
                itemCartData = {
                    name: data.name,
                    // email: data.email,
                    mobile: data.mobile,
                    designation: data.designation,
                    company: data.company,
                    companyLogo: selectImage
                };
            }
            productDetails = {
                ...itemCartData,
                productId: id
            };
            const addObj = isUserLoggedIn()
                ? productDetails
                : {
                      ProductMaster: ProductMaster,
                      itemData: itemCartData,
                      quantity: qualitySelected
                  };
            await dispatch(addToCart(addObj))
                .unwrap()
                .then(() => {
                    navigate('/cart');
                });
        } catch (error: any) {
            console.error('Error:', error);
            return toast.error(error?.response?.data?.message || 'Something went wrong!');
        }
    };

    const getSingleProduct = async () => {
        await dispatch(getProductById(id as string));
    };
    useEffect(() => {
        if (id) {
            getSingleProduct();
        }
    }, [id]);

    // const notifyAddToCart = () => {
    //     toast.custom(
    //         (t) => (
    //             <NotifyAddToCart
    //                 productName={ProductData.name}
    //                 productImage={image}
    //                 qualitySelected={qualitySelected}
    //                 show={t.visible}
    //                 priceSelected={ProductData.price}
    //                 sizeSelected={sizeSelected}
    //                 variantActive={variantActive}
    //             />
    //         ),
    //         { position: 'top-right', id: 'nc-product-notify', duration: 3000 }
    //     );
    // };

    const renderStatus = () => {
        if (!status) {
            return null;
        }
        const CLASSES =
            'absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300';
        if (status === 'New in') {
            return (
                <div className={CLASSES}>
                    <SparklesIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === '50% Discount') {
            return (
                <div className={CLASSES}>
                    <IconDiscount className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === 'Sold Out') {
            return (
                <div className={CLASSES}>
                    <NoSymbolIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === 'limited edition') {
            return (
                <div className={CLASSES}>
                    <ClockIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        return null;
    };

    const renderSectionContent = () => {
        return (
            <div className="space-y-7 2xl:space-y-8">
                {/* ---------- 1 HEADING ----------  */}
                <div>
                    <h2 className="text-2xl sm:text-3xl font-semibold">{ProductData && ProductData.name}</h2>

                    <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
                        {/* <div className="flex text-xl font-semibold">$112.00</div> */}
                        <Prices
                            contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                            price={ProductData?.price}
                            isSale={ProductData?.isSale}
                            salePrice={ProductData?.salePrice}
                        />

                        <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>

                        <div className="flex items-center">
                            <a className="flex items-center text-sm font-medium"> SKU : {ProductData.sku}</a>
                            &nbsp; &nbsp;
                            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
                            &nbsp; &nbsp;
                            <a className="flex items-center text-sm font-medium"> Dimension : {ProductData.dimension}</a>
                        </div>
                    </div>
                </div>

                {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
                {/* <div className="">{renderVariants()}</div> */}
                {/* <div className="">{renderSizeList()}</div> */}

                {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}

                <div>
                    <Form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                        {ProductData.isCustom ? (
                            <Fragment>
                                <div className="mt-6 space-y-7 lg:space-y-8">
                                    <div className="">{renderVariantList()}</div>
                                </div>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Design File</span>

                                    <Input
                                        type="file"
                                        style={{ padding: '9px' }}
                                        // placeholder="text"
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                        // onInvalid={(e) => {
                                        //   errors.password && true;
                                        // }}
                                        {...register('designFile', {
                                            onChange: (e) => {
                                                e.preventDefault();
                                                handleImageUpload(e, setSelectedDesignFile);
                                            }
                                        })}
                                    />
                                </label>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Name</span>
                                    <Input
                                        type="text"
                                        placeholder="Enter Name"
                                        className="mt-1"
                                        // onInvalid={(e) => {
                                        //   errors.password && true;
                                        // }}
                                        {...register('name')}
                                    />
                                    {errors && errors?.name && <FormFeedback>{errors?.name?.message?.toString()}</FormFeedback>}
                                </label>
                                {/* <label className="block">
                                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Email</span>
                                    <Input type="email" placeholder="Enter Email" className="mt-1" {...register('email')} />
                                    {errors && errors?.email && <FormFeedback>{errors?.email?.message?.toString()}</FormFeedback>}
                                </label> */}
                                <label className="block">
                                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Mobile</span>
                                    <Input type="tel" placeholder="Enter Mobile No." className="mt-1" {...register('mobile')} />
                                    {errors && errors?.mobile && <FormFeedback>{errors?.mobile?.message?.toString()}</FormFeedback>}
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Company Name (Optional)</span>
                                    <Input type="text" placeholder="Enter Company Name" className="mt-1" {...register('company')} />
                                </label>
                                <label className="block">
                                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                        Designation
                                    </span>
                                    <Input type="text" placeholder="Enter Designation" className="mt-1" {...register('designation')} />
                                    {errors && errors?.designation && (
                                        <FormFeedback>{errors?.designation?.message?.toString()}</FormFeedback>
                                    )}
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Logo</span>

                                    <Input
                                        type="file"
                                        style={{ padding: '9px' }}
                                        // placeholder="text"
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                        // onInvalid={(e) => {
                                        //   errors.password && true;
                                        // }}
                                        {...register('companyLogo', {
                                            onChange: (e) => {
                                                e.preventDefault();
                                                handleImageUpload(e, setSelectedImage);
                                            }
                                        })}
                                    />
                                </label>
                            </Fragment>
                        )}

                        <div className="flex space-x-3.5">
                            <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
                                <NcInputNumber defaultValue={qualitySelected} onChange={setQualitySelected} />
                            </div>
                            <ButtonPrimary className="flex-1 flex-shrink-0" type="submit">
                                <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
                                <span className="ml-3">Add to cart</span>
                            </ButtonPrimary>
                        </div>
                    </Form>
                </div>

                {/*  */}
                <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
                {/*  */}

                {/* ---------- 5 ----------  */}
                <AccordionInfo product={ProductData} />
                {/* ---------- 6 ----------  */}
                <div className="hidden xl:block">
                    <Policy />
                </div>
            </div>
        );
    };

    const renderDetailSection = () => {
        return (
            <div className="">
                <h2 className="text-2xl font-semibold">Product Details</h2>
                <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
                    {ProductData.details ? <p dangerouslySetInnerHTML={{ __html: ProductData.details }} /> : null}
                </div>
            </div>
        );
    };

    const renderVariantList = () => {
        if (!allVariant.length) {
            return null;
        }
        return (
            <div>
                <div className="flex justify-between font-medium text-sm">
                    <label htmlFor="">
                        <span className="">
                            Card Type:
                            <span className="ml-1 font-semibold">{variantActive}</span>
                        </span>
                    </label>
                    {/* <a target="_blank" rel="noopener noreferrer" href="##" className="text-primary-6000 hover:text-primary-500">
                        See sizing chart
                    </a> */}
                </div>
                <div className="grid grid-cols-2 gap-2 mt-3">
                    {allVariant.map((v, index) => {
                        const isActive = v === variantActive;
                        return (
                            <div
                                key={index}
                                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 
                ${
                    isActive
                        ? 'bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000'
                        : 'border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700'
                }`}
                                onClick={() => {
                                    setVariantActive(v);
                                }}
                            >
                                {v}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-ProductDetailPage ${className}`}>
            {/* MAIn */}
            <main className="container mt-5 lg:mt-11">
                <div className="lg:flex">
                    {/* CONTENT */}
                    <div className="w-full lg:w-[55%] ">
                        {/* HEADING */}
                        <div className="relative">
                            <div className="aspect-w-16 aspect-h-16">
                                <img src={image} className="w-full rounded-2xl" alt="product detail 1" />
                            </div>
                            {renderStatus()}
                            {/* META FAVORITES */}
                            <LikeButton className="absolute right-3 top-3 " />
                        </div>
                        <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                            {ProductData?.ProductImageMappings &&
                                ProductData?.ProductImageMappings.length >= 2 &&
                                ProductData?.ProductImageMappings.filter((i, index) => index !== 0).map((item, index) => {
                                    return (
                                        <div key={index} className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16">
                                            <img
                                                src={url + item.ProductImage.path}
                                                className="w-full rounded-2xl object-cover"
                                                alt="product detail 1"
                                            />
                                        </div>
                                    );
                                })}
                            {ProductData?.ProductImageMappings &&
                                ProductData?.ProductImageMappings.length < 3 &&
                                Array.from({
                                    length:
                                        ProductData?.ProductImageMappings.length === 0 || ProductData?.ProductImageMappings.length === 1
                                            ? 2
                                            : 1
                                }).map((_, index) => {
                                    return (
                                        <div key={index} className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16">
                                            <img src={DefaultImage} className="w-full rounded-2xl object-cover" alt="product detail 1" />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    {/* SIDEBAR */}
                    <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">{renderSectionContent()}</div>
                </div>

                {/* DETAIL AND REVIEW */}
                <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
                    <div className="block xl:hidden">
                        <Policy />
                    </div>

                    {renderDetailSection()}

                    <hr className="border-slate-200 dark:border-slate-700" />

                    <SectionSliderCollections />
                    {/* {renderReviews()} */}

                    {/* OTHER SECTION */}
                    {/* <SectionSliderProductCard
                        heading="Customers also purchased"
                        subHeading=""
                        headingFontClassName="text-2xl font-semibold"
                        headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
                    /> */}

                    {/* SECTION */}
                    <div className="pb-20 xl:pb-28 lg:pt-14">
                        <SectionPromo2 />
                    </div>
                </div>
            </main>

            {/* MODAL VIEW ALL REVIEW */}
            <ModalViewAllReviews show={isOpenModalViewAllReviews} onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)} />
        </div>
    );
};

export default ProductDetailPage;
